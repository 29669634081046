import React from 'react';
import { useHistory } from 'react-router-dom';

import './styles/NavHeader.css';

export const NavHeader = ({ backLocation, title, style }) => {
    const history = useHistory();

    const handleClick = () => {
        history.push(backLocation);
    };

    return (
        <div className="navHeader" style={style}>
            <div className="navHeader__container">
                <div className="navHeader__back">
                    <button onClick={handleClick} aria-label="Back">
                        <svg className="navHeader__backIcon" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={4} d="M15 19l-7-7 7-7" /></svg>
                    </button>
                </div>
                <div className="navHeader__title">{title}</div>
            </div>
        </div>
    )
}

NavHeader.defaultProps = {
    backLocation: '/',
    title: ''
};