import React, { useEffect, Fragment } from 'react';
import { useDispatch } from 'react-redux';

import Content from '../../components/layout/Content';
import { RESET_REGISTRATION } from '../../types/authTypes';

import './Register.css';

const RegisterComplete = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo(0, 0);
        
        return () => {
            dispatch({ type: RESET_REGISTRATION });
        };
    }, []);

    return (
        <Content>
            <h1 className="registerComplete__title">Registration Complete</h1>
            <p className="registerComplete__message">We've sent you an email. Before you can login, please follow one of the links to verify your account.</p>
        </Content>
    )
}

export default RegisterComplete;
