import axios from 'axios';
import Cookie from 'js-cookie';

import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAIL, REGISTER_REQUEST, REGISTER_SUCCESS, REGISTER_FAIL, RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAIL, LOGOUT } from '../types/authTypes';

export const login = (email, password) => async dispatch => {
    try {
        dispatch({ type: LOGIN_REQUEST });

        const { data } = await axios.post('/app_handler.php', { email, password }, { headers: { 'WG-Method': 'LOGIN' }});

        Cookie.set('accessToken', data.jwt, { expires: 30 });
        Cookie.set('user', JSON.stringify(data.user));
        dispatch({ type: LOGIN_SUCCESS, payload: data.user });
    } catch (error) {
        dispatch({ type: LOGIN_FAIL, payload: error?.response.data.errors });
    }
}

export const register = (firstname, lastname, email, repeatEmail, personalEmail, phone, repeatPhone, dob, graduation, institution, institutionOther, password, repeatPassword) => async dispatch => {
    try {
        dispatch({ type: REGISTER_REQUEST });

        const { data } = await axios.post('/app_handler.php', { firstname, lastname, email, repeat_email: repeatEmail, personal_email: personalEmail, phone, repeat_phone: repeatPhone, dob, graduation, institution, institution_other: institutionOther, password, repeat_password: repeatPassword }, { headers: { 'WG-Method': 'REGISTER' }});

        dispatch({ type: REGISTER_SUCCESS });
    } catch (error) {
        dispatch({ type: REGISTER_FAIL, payload: error?.response.data.errors });
    }
};

export const logout = () => dispatch => {
    dispatch({ type: LOGOUT });
    Cookie.remove('accessToken');
    Cookie.remove('user');
};

export const resetPassword = (phone) => async dispatch => {
    try {
        dispatch({ type: RESET_PASSWORD_REQUEST });

        const { data } = await axios.post('/app_handler.php', { phone }, { headers: { 'WG-Method': 'RESET_PASSWORD' }});

        dispatch({ type: RESET_PASSWORD_SUCCESS, payload: data.message });
    } catch (error) {
        dispatch({ type: RESET_PASSWORD_FAIL, payload: error?.response.data.errors });
    }
}