import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ReactComponent as LoginIcon } from '../../assets/login.svg';
import { ReactComponent as UserCircleIcon } from '../../assets/user-circle.svg';
import { ReactComponent as Home } from '../../assets/home.svg';
import { ReactComponent as Check } from '../../assets/check.svg';
import { ReactComponent as Search } from '../../assets/search.svg';
import QRCode from '../../assets/QRCode';

import './BottomNav.css';

const BottomNav = () => {
    const authSelector = useSelector((state) => state.auth);
    const { isAuthenticated } = authSelector;

    return (
        <div className="bottomNav">
            {isAuthenticated ? (
                <Fragment>
                    <NavLink to="/" exact className="bottomNav__link" activeClassName="active"><Home />Home</NavLink>
                    <NavLink to="/student-offers" exact className="bottomNav__link" activeClassName="active"><Search style={{ width: '22px', height: '22px' }} />Search</NavLink>
                    {/* <NavLink to="/student-offers-v2" exact className="bottomNav__link" activeClassName="active"><Search style={{ width: '22px', height: '22px' }} />Search V2</NavLink>
                    <NavLink to="/saved" exact className="bottomNav__link" activeClassName="active"><Check style={{ width: '22px', height: '22px' }} />Saved</NavLink> */}
                    <NavLink to="/code" exact className="bottomNav__link" activeClassName="active"><QRCode style={{ width: '22px', height: '22px' }} />QR Code</NavLink>
                </Fragment>
            ) : (
                <Fragment>
                    <NavLink to="/login" className="bottomNav__link" activeClassName="active"><LoginIcon />Login</NavLink>
                    <NavLink to="/register" className="bottomNav__link" activeClassName="active"><UserCircleIcon />Register</NavLink>
                </Fragment>
            )}
        </div>
    )
}

export default BottomNav;
