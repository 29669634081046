import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Cookie from 'js-cookie';
import axios from 'axios';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import Content from '../../components/layout/Content';
import BottomNav from '../../components/layout/BottomNav';
import { Card, OfferCard } from '../../components/utils/Card';
import { SearchOffers } from '../../components/utils/SearchOffers';

const OfferCategories = () => {
    const [featured, setFeatured] = useState([]);
    const [categories, setCategories] = useState([]);
    const [offers, setOffers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('')
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        async function getFeaturedOffers() {
            setLoading(true);
            try {
                const { data } = await axios.get('/app_handler.php', { headers: { 'Wg-Method': 'GET_FEATURED', 'Wg-Key': Cookie.get('accessToken') }}); 
                
                if (data) {
                    setFeatured(data);
                }

                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        }

        async function getCategories() {
            setLoading(true);
            try {
                const { data } = await axios.get('/app_handler.php', { headers: { 'Wg-Method': 'RETURN_CATEGORIES', 'Wg-Key': Cookie.get('accessToken') } });

                if (data) {
                    setCategories(data.categories);
                }
                setLoading(false);

            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };

        getFeaturedOffers();
        getCategories();
    }, []);

    const renderedFeatured = featured.map((feature, i) => {
        const size = feature?.field_value === '45' ? 'XL' : 'L';

        return (
            <div key={feature.primary_id} style={{ display: 'block', marginTop: i === 0 ? '0' : '1rem' }}>
                <OfferCard size={size} color="#fff" offer={feature} />
            </div>
        );
    });

    const renderedCategories = categories.map((category, i) => (
        <Link key={category.category_id} to={`/student-offers/${category.category_id}`} style={{ display: 'block', marginTop: i === 0 ? '0' : '1rem' }}>
            <Card size="S">
                <div>{category.category_name}</div>
            </Card>
        </Link>
    ));

    const renderedOffers = offers && (
        <div>
            <h2>{offers.length} dun.deals found for{searchTerm ? ` '${searchTerm}'` : ''}</h2>
            {offers.map((offer, i) => (
                <Link key={offer?.offer_id} to={`/student-offers/${offer?.offer_id}`} style={{ display: 'block', marginTop: i === 0 ? '0' : '1rem' }}>
                    <OfferCard size="S" offer={offer} />
                </Link>
            ))}
        </div>
    );

    return (
        <Fragment>
            <Header />
            <Menu />
            <div style={{ marginTop: '76.27px' }}>
                <SearchOffers setLoading={setLoading} onFetch={setOffers} setTerm={setSearchTerm} />
            </div>
            <Content style={{ marginTop: 0 }}>
                <h2>Featured</h2>
                {renderedFeatured}
                {offers.length > 0 && (
                    <Fragment>
                        {renderedOffers}
                    </Fragment>
                )}
                <h2>Categories</h2>
                {renderedCategories}
            </Content>
            <BottomNav />
        </Fragment>
    )
}

export default OfferCategories;
