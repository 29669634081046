import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Cookie from 'js-cookie';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import BottomNav from '../../components/layout/BottomNav';
import Content from '../../components/layout/Content';
import { Card, OfferCard } from '../../components/utils/Card';
import { Banner } from '../../components/utils/Banner';

import Drinks from '../../assets/drinks.jpg';
import Food from '../../assets/food.jpg';
import Beauty from '../../assets/beauty.jpg';
import Cafes from '../../assets/cafes.jpg';
import Music from '../../assets/music.jpg';

import './Home.css';

const Home = () => {
    const [categories, setCategories] = useState([]);
    const [offer, setOffer] = useState(null);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [loadingOffer, setLoadingOffer] = useState(false);
    const [errorOffer, setErrorOffer] = useState(null);

    useEffect(() => {
        async function getCategories() {
            setLoading(true);
            try {
                const { data } = await axios.get('/app_handler.php', { headers: { 'Wg-Method': 'HOME_PAGE', 'Wg-Key': Cookie.get('accessToken') } });

                if (data) {
                    setCategories(data?.categories);
                    setTotal(data?.total_offers)
                }
                setLoading(false);

            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };

        async function getFeaturedOffers() {
            setLoadingOffer(true);
            try {
                const { data } = await axios.get('/app_handler.php', { headers: { 'Wg-Method': 'GET_FEATURED', 'Wg-Key': Cookie.get('accessToken') }}); 
                
                if (data) {
                    setOffer(data[0]);
                }

                setLoadingOffer(false);
            } catch (error) {
                setErrorOffer(error);
                setLoadingOffer(false);
            }
        }

        getCategories();
        getFeaturedOffers();
    }, []);

    return (
        <Fragment>
            <Header />
            <Menu />
            <Banner style={{ marginTop: '76.27px' }} text="Check out your latest discount offers" />
            <Content style={{ marginTop: '0' }}>
            <Card backgroundColor="#03d5e5" color="#fff"><em>{total}</em> exclusive dun.deals</Card>
            <div style={{ marginTop: '1rem' }}>
                <OfferCard size={offer?.field_value === '45' ? 'XL' : 'L'} offer={offer} />
            </div>
            <h2>Popular Categories</h2>
            <Card>
                <div className="grid-container">
                    {categories && categories.map((category) => (
                        <Link key={category?.category_id} to={`/student-offers/${category?.category_id}`}>
                            <div>
                                <img src={category?.category_image} />
                                <div className="label">{category?.category_name}</div>
                            </div>
                        </Link>
                    ))}
                </div>
            </Card>
            </Content>
            <BottomNav />
        </Fragment>
    )
}

export default Home;
