import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Cookie from 'js-cookie';
import ClipLoader from 'react-spinners/ClipLoader';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import Content from '../../components/layout/Content';
import BottomNav from '../../components/layout/BottomNav';
import { NavHeader } from '../../components/utils/NavHeader';
import { Card } from '../../components/utils/Card';

import './styles/OfferDetails.css';

const OfferDetails = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [offer, setOffer] = useState({});
    const [toggle, setToggle] = useState(false);
    const [claim, setClaim] = useState(false);
    const { offerId } = useParams();

    const toggleHowToClaim = async () => {
        setToggle(!toggle);
    };

    const howToClaim = async () => {
        setClaim(!toggle);
    };

    useEffect(() => {
        async function getOffer() {
            setLoading(true);
            try {
                const { data }  = await axios.get('/app_handler.php', { headers: { 'Wg-Method': 'LOAD_OFFER', 'Wg-Key': Cookie.get('accessToken'), 'Wg-OfferID': offerId } });

                if (data) {
                    setLoading(false);
                    setOffer(data);
                }
            } catch (error) {
                setLoading(false);
            }
        }

        getOffer();
    }, []);

    useEffect(() => {
        async function setTracking() {
            if (toggle) {
                try {
                    const { data } = await axios.post('/app_handler.php', { offer_id: offerId }, { headers: { 'Wg-Method': 'SET_HOW_TO_CLAIM_TOGGLE_TRACKING', 'Wg-Key': Cookie.get('accessToken') }});
                } catch (error) {
                    console.error(error);
                }
            }
        }

        setTracking();
    }, [toggle]);

    return (
        <Fragment>
            <Header />
            <Menu />
            <NavHeader backLocation={`/student-offers/${offer?.offer_category_id}`} title={offer?.location_company} style={{ marginTop: '76.27px' }} />
            <div className="page" style={{ marginTop: 0 }}>
                <div class="details__container">
                    {loading ? (
                        <div>
                            <ClipLoader color="#03d5e5" />
                        </div>
                    ) : (
                        <Fragment>
                            <div className="details__cardContainer">
                                <div className="details__banner" style={{ backgroundColor: offer?.offer_header_banner ? 'none' : '#03d5e5' }}>
                                    <div className="details__bannerContainer">
                                        {offer?.offer_header_banner && (<img className="details__image" src={offer?.offer_header_banner} alt={offer?.offer_title} />)}
                                    </div>
                                    <div className="details__logoContainer">
                                        <img className="details__logo" src={offer?.location_logo} alt={offer?.location_company} />
                                    </div>
                                </div>
                                <div className="details__description">
                                    <h1 className="details__offerTitle">{offer?.offer_title}</h1>
                                    <div dangerouslySetInnerHTML={{__html: offer?.offer_description}} />
                                    <div dangerouslySetInnerHTML={{__html: offer?.location_description}} />
                                </div>
                            </div>
                            <div className="details_locationDesc">
                                <div className="details__header">
                                    <h2 class="details__howToUse">How do I use this offer?</h2>
                                    <button onClick={toggleHowToClaim}>{toggle ? (
                                        <Fragment>
                                            <span style={{ paddingRight: '0.5rem' }}>Hide</span>
                                            <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20 12H4" /></svg>
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <span style={{ paddingRight: '0.5rem' }}>Show</span>
                                            <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" /></svg>
                                        </Fragment>
                                    )}</button>
                                </div>
                                <div>
                                    {toggle && (
                                        <div dangerouslySetInnerHTML={{__html: offer?.offer_how_to_claim}} />
                                        // <button onClick={howToClaim}>Claim now</button>
                                    )}
                                </div>
                            </div>
                        </Fragment>
                    )}
                </div>
            </div>
            <BottomNav />
        </Fragment>
    )
}

export default OfferDetails;
