import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PulseLoader from 'react-spinners/PulseLoader';

import Content from '../../components/layout/Content';
import { register } from '../../actions/authActions';
import { closeMenu } from '../../actions/appActions';

import { days, months } from '../../constants/dates';

import 'react-datepicker/dist/react-datepicker.css';
import './Register.css';

const years = [];
const graduationYears = [];
const currentYear = new Date().getFullYear();

for (let i = currentYear; i >= 1920; i--) {
    years.push(i);
}

for (let i = currentYear; i <= currentYear + 7; i++) {
    graduationYears.push(i);
}

const RegisterForm = () => {
    const dispatch = useDispatch();
    const authSelector = useSelector((state) => state.auth);
    const { loading, errors } = authSelector;

    const termsEl = useRef(null);
    const [termsError, setTermsError] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [repeatEmail, setRepeatEmail] = useState('');
    const [personalEmail, setPersonalEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [repeatPhone, setRepeatPhone] = useState('');
    const [day, setDay] = useState(new Date().getDate());
    const [month, setMonth] = useState(new Date().getMonth() + 1);
    const [year, setYear] = useState(new Date().getFullYear() - 18);
    const [graduation, setGraduation] = useState('');
    const [institution, setInstitution] = useState('');
    const [institutionOther, setInstitutionOther] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    
    const onSubmit = e => {
        e.preventDefault();

        if (termsEl.current.checked) {
            setTermsError(null);
            dispatch(register(firstName, lastName, email, repeatEmail, personalEmail, phone, repeatPhone, year + '-' + month + '-' + day, graduation, institution, institutionOther, password, repeatPassword));
        } else {
            setTermsError('Please check the terms to continue.');
        }
    };

    useEffect(() => {
        dispatch(closeMenu());
    }, [dispatch]);

    return (
        <Content>
            <h1>Register</h1>
            <span className="login__welcome">Please fill out all fields</span>
            <form className="login__form" onSubmit={onSubmit}>
                <div className="form-group">
                    <label className="form__label" htmlFor="firstName">First Name</label>
                    <input className="form__input" type="text" id="firstName" name="firstName" value={firstName} autoComplete={false} onChange={(e) => setFirstName(e.target.value)} />
                    <div className="error">{errors?.firstname?.message}</div>
                </div>
                <div className="form-group">
                    <label className="form__label" htmlFor="lastName">Last Name</label>
                    <input className="form__input" type="text" id="lastName" name="lastName" value={lastName} autoComplete={false} onChange={(e) => setLastName(e.target.value)} />
                    <div className="error">{errors?.lastname?.message}</div>
                </div>
                <div className="form-group">
                    <label className="form__label" htmlFor="email">Student Email (.ac.uk emails only)</label>
                    <input className="form__input" type="text" id="email" name="email" value={email} autoComplete={false} onChange={(e) => setEmail(e.target.value)} />
                    <div className="error">{errors?.email?.message}</div>
                </div>
                <div className="form-group">
                    <label className="form__label" htmlFor="repeatEmail">Retype Student Email</label>
                    <input className="form__input" type="text" id="repeatEmail" name="repeatEmail" value={repeatEmail} autoComplete={false} onChange={(e) => setRepeatEmail(e.target.value)} />
                    <div className="error">{errors?.repeat_email?.message}</div>
                </div>
                <div className="form-group">
                    <label className="form__label" htmlFor="personalEmail">Personal Email</label>
                    <input className="form__input" type="text" id="personalEmail" name="personalEmail" value={personalEmail} autoComplete={false} onChange={(e) => setPersonalEmail(e.target.value)} />
                    <div className="error">{errors?.personal_email?.message}</div>
                </div>
                <div className="form-group">
                    <label className="form__label" htmlFor="phone">Mobile Phone</label>
                    <input className="form__input" type="text" id="phone" name="phone" value={phone} autoComplete={false} onChange={(e) => setPhone(e.target.value)} />
                    <div className="error">{errors?.phone?.message}</div>
                </div>
                <div className="form-group">
                    <label className="form__label" htmlFor="repeatPhone">Retype Mobile Phone</label>
                    <input className="form__input" type="text" id="repeatPhone" name="repeatPhone" value={repeatPhone} autoComplete={false} onChange={(e) => setRepeatPhone(e.target.value)} />
                    <div className="error">{errors?.repeat_phone?.message}</div>
                </div>
                <div className="form-group">
                    <label className="form__label" htmlFor="day">Date of Birth</label>
                    <div className="form__selectRow">
                        <select className="form__select" name="day" value={(day).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})} onChange={(e) => setDay(e.target.value)}>
                            {days.map((day) => (
                                <option value={day.value}>{day.label}</option>
                            ))}
                        </select>
                        <select className="form__select" name="month" value={(month).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})} onChange={(e) => setMonth(e.target.value)}>
                            {months.map((month) => (
                                <option value={month.value}>{month.label}</option>
                            ))}
                        </select>
                        <select className="form__select" name="year" value={year} onChange={(e) => setYear(e.target.value)}>
                            {years.map((year) => (
                                <option value={year}>{year}</option>
                            ))}
                        </select>
                    </div>
                    <div className="error">{errors?.dob?.message}</div>
                </div>
                <div className="form-group">
                    <label className="form__label" htmlFor="graduation">Graduation Year</label>
                    <div className="form__selectRow">
                        <select className="form__select" name="graduation" value={graduation} onChange={(e) => setGraduation(e.target.value)}>
                            <option value="">Please select a year</option>
                            {graduationYears.map((year) => (
                                <option value={year}>{year}</option>
                            ))}
                        </select>
                    </div>
                    <div className="error">{errors?.graduation?.message}</div>
                </div>
                <div className="form-group">
                    <label className="form__label" htmlFor="institution">Institution</label>
                    <div className="form__selectRow">
                        <select className="form__select" name="institution" value={institution} onChange={(e) => setInstitution(e.target.value)}>
                            <option value="">Select Institution</option>
                            <option value="25">Abertay University</option>
							<option value="26">D &amp; A Collage </option>
							<option value="27">St Andrews University</option>
							<option value="24">University of Dundee</option>
							<option value="28">Other</option>
                        </select>
                    </div>
                    <div className="error">{errors?.institution?.message}</div>
                </div>
                {institution === "28" && (
                    <div className="form-group">
                        <label className="form__label" htmlFor="institutionOther">Please specify Institution</label>
                        <input className="form__input" type="text" id="institutionOther" name="institutionOther" value={institutionOther} autoComplete={false} onChange={(e) => setInstitutionOther(e.target.value)} />
                        <div className="error">{errors?.institution_other?.message}</div>
                    </div>
                )}
                <div className="form-group">
                    <label className="form__label" htmlFor="password">Password</label>
                    <input className="form__input" type="password" id="password" name="password" value={password} autoComplete={false} onChange={(e) => setPassword(e.target.value)} />
                    <div className="error">{errors?.password?.message}</div>
                </div>
                <div className="form-group">
                    <label className="form__label" htmlFor="repeatPassword">Retype Password</label>
                    <input className="form__input" type="password" id="repeatPassword" name="repeatPassword" value={repeatPassword} autoComplete={false} onChange={(e) => setRepeatPassword(e.target.value)} />
                    <div className="error">{errors?.repeat_password?.message}</div>
                </div>
                <div className="form-group--checkbox">
                    <label htmlFor="terms" className="form__label">By registering with dun.deals I agree to the following <a href="https://www.dundeals.co.uk/terms-of-use/" target="_blank">Terms &amp; Conditions</a>
                        <input className="form__checkbox" type="checkbox" id="terms" name="terms" ref={termsEl} />
                    </label>
                    <div className="error">{termsError}</div>
                </div>
                <div className="form__buttons">
                    <button aria-label="Register" disabled={loading} className="form__button form__button--inline" type="submit">Register <PulseLoader loading={loading} color={'#ffffff'} css={'margin-left: 8px'} size={5} /></button>
                </div>
            </form>
        </Content>
    )
}

export default RegisterForm;
