import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import './styles/Card.css';

export const Card = ({ backgroundColor, backgroundImage, color, size, children }) => {
    return (
        <div className={`card${size === 'S' ? ' card--small' : ''}${size === 'L' ? ' card--large' : ''}${size === 'XL' ? ' card--xlarge' : ''}`} style={{ backgroundColor, color, backgroundImage, backgroundImage: backgroundImage ? `url(${backgroundImage})` : 'none' }}>
            {children}
        </div>
    )
}

export const OfferCard = ({ color, size, offer }) => {
    const backgroundColor = offer?.offer_bg_color ? offer?.offer_bg_color : '#fff';

    return (
        <Link to={`/student-offer/${offer?.primary_id}`}>
            <Card size={size} backgroundColor={(offer?.field_value === '45' || offer?.field_value === '44') && offer?.location_logo ? 'none' : backgroundColor} color={color} backgroundImage={offer?.offer_banner}>
                <Fragment>
                    {offer?.field_value === '45' || offer?.field_value === '44' || offer?.offer_type === 'Super Listing' || offer?.offer_type === 'Premium Listing' ? (
                        <div className="card__content">
                            {offer?.location_logo && !offer?.offer_banner && (<div><img src={offer?.location_logo} /></div>)}
                            {!offer?.offer_banner && (<div>{offer?.location_company}</div>)}
                        </div>
                    ) : (
                        <div className="card__content">
                            {!offer?.offer_banner && (
                                <Fragment>
                                    <div>{offer?.location_company}</div>
                                    {offer?.location_logo && (<div><img className="card__companyLogo" src={offer?.location_logo} /></div>)}
                                </Fragment>
                            )}
                        </div>
                    )}
                </Fragment>
            </Card>
        </Link>
    );
};

Card.defaultProps = {
    backgroundColor: '#fff',
    color: '#000',
    size: 'M'
};

OfferCard.defaultProps = {
    size: 'M',
    offer: {}
};