import React from 'react';
import { useSelector } from 'react-redux';

import './styles/Banner.css';

export const Banner = ({ text, style }) => {
    const authSelector = useSelector((state) => state.auth);
    const { user } = authSelector;

    return (
        <div style={style} className="banner">
            {user && (<h2>Hi {user?.displayName}</h2>)}
            <p>{text}</p>
        </div>
    )
}