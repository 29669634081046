import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import Logo from '../../assets/logo.jpg';
import Menu from '../../assets/menu.svg';

import { toggleMenu } from '../../actions/appActions';

import './Header.css';

const Header = (props) => {
    const dispatch = useDispatch();
    const { isLogin } = props;

    return (
        <div className="header">
            <div className="header__top">
                <Link to="/">
                    <img className="header__logo" src={Logo} alt="Dun Deals" />
                </Link>
                {!isLogin && (
                    <button aria-label="Menu" onClick={() => dispatch(toggleMenu())} className="header__menuIcon"><img src={Menu} alt="Menu" /></button>
                )}
            </div>
        </div>
    )
}

export default Header;
