import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Cookie from 'js-cookie';

import './styles/SearchOffers.css';

export const SearchOffers = ({ setLoading, onFetch, setTerm }) => {
    const [search, setSearch] = useState('');
    const isFirstRun = useRef(true);

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }

        async function getOffers() {
            setTerm(search);
            try {
                setLoading(true);
                const { data } = await axios.get('/app_handler.php', { headers: { 'Wg-Method' : 'SEARCH_OFFERS', 'Wg-Term': search, 'Wg-Key': Cookie.get('accessToken') }})
            
                if (data) {
                    onFetch(data?.offers);
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }

        getOffers();

    }, [search]);

    return (
        <div className="search">
            <div className="search__input">
                <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} />
            </div>
            <div className="search__label">Search by category, offer, or business</div>
        </div>
    )
}